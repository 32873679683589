import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import axios from "axios"

import { sha256 } from 'js-sha256';

import Introduction from "../components/Global/Introduction"
import "../components/Contact/ContactTemplate.css"



function ContactTemplate(props) {
    console.log(props)
    let data = props.data.wpgraphql;
    const [formError, setFormError] = useState("")
    const [emailResponse, setEmailResponse] = useState("")
    const [emailFailed, setEmailFailed] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://nextyounutrition.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://nextyounutrition.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    function contactUs() {
        let fullName = document.getElementById("fullName").value
        let email = document.getElementById("email").value
        let telephone = document.getElementById("telephone").value
        let txtArea = document.getElementById("txtArea").value

        if (fullName.length > 4) {
            if (telephone.length >= 10) {
                if (validateEmail(email)) {
                    if (txtArea.length >= 10) {
                        console.log("SEND EMAIL")
                        let payload = {
                            fullName: fullName,
                            email: email,
                            message: txtArea,
                            telephone: telephone
                        }

                        setLoading(true)

                        axios.post(`https://admin.nextyounutrition.gr/wp-json/myplugin/v1/contactEmail`, payload)
                            .then(function (response) {
                                // handle success
                                setEmailFailed(false)
                                setEmailSent(true)
                                setEmailResponse(response.data)
                                setLoading(false)

                                window.dataLayer.push({
                                    'event': 'contact_form'
                                });

                                window.fbq('track', 'Lead');

                                let fbId = localStorage.getItem("fbclid")

                                if (fbId) {
                                    fetch('https://graph.facebook.com/v10.0/163187788976281/events/?access_token=EAAB56ZCrMsZAEBABkyFdRFeZBVHEcdkwA2mDQWpoECaZAHjhKby2RL4ZAMXJym3lE1ruxY3w4fuZCZAP0z2cnTw8GNQJlaZAOHpRu6tfvTwLYc3xMzntZBdBtM1ceGg9ZC1dnVJuIp6NplTij1rzEPKWKZBBFETslRlOD23sJg4I7aPcj5WLwOjCHwpCEY7QjFhyxUZD', {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json' },
                                        body: JSON.stringify({
                                            'data': [
                                                {
                                                    "event_name": "Lead",
                                                    "event_id": fbId,
                                                    "event_source_url": window.location.href.split('?')[0],
                                                    "event_time": Math.floor(new Date().getTime() / 1000),
                                                    "action_source": "website",
                                                    "user_data": {
                                                        "em": sha256(email),
                                                        "client_user_agent": navigator.userAgent
                                                    },
                                                }
                                            ]
                                        }),
                                    })
                                        .then((res) => res.json())
                                        .then((data) => {
                                            console.log(data)
                                        })
                                        .catch((err) => console.log(err));
                                }

                            })
                            .catch(function (error) {
                                // handle error
                                setEmailFailed(true)
                                setEmailResponse(data.responses.failed)
                                setLoading(false)
                            })

                        setFormError("")
                    } else {
                        setFormError("Το μήνυμά σας θα πρέπει να ξεπερνά τους 9 χαρακτήρες")
                    }
                } else {
                    setFormError("Το email σας δεν είναι έγκυρο")
                }
            } else {
                setFormError("Ο αριθμός τηλεφώνου δεν είναι έγκυρος")
            }
        } else {
            console.log(fullName.length)
            if (fullName.length === 0) {
                setFormError("Συμπληρώστε το ονοματεπώνυμό σας")
            } else {
                setFormError("To ονοματεπώνυμο θα πρέπει να ξεπερνά τους 4 χαρακτήρες")
            }
        }


    }

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <Layout header={data.menu} footer={data.footer} appointment={data.template.appCpt} metaData={constructMetaData(data.page, props.pageContext.currentPage)} >
            <div>
                <section>
                    <Introduction backImg={data.page.contactPageAcf.introductionBackgroundImage} title={data.page.contactPageAcf.introductionHeader} />
                </section>
                <section className="w-full contact-info">
                    <div className="container m-auto px-4 py-24">
                        <div className="flex flex-wrap w-full">
                            <div className="w-full md:w-full xl:w-1/3 mt-8 lg:mt-0">
                                <div className="m-4 py-8 lg:px-8 xl:px-16 ci-wrapper mx-auto">
                                    <Img fixed={data.page.contactPageAcf.contactAddress.icon.imageFile.childImageSharp.fixed} alt={data.page.contactPageAcf.contactAddress.icon.altText} style={{ zIndex: 9 }} />
                                    <div className="ci-divider" />
                                    <h6>{data.page.contactPageAcf.contactAddress.title}</h6>
                                    <p>{data.page.contactPageAcf.contactAddress.address}</p>
                                </div>
                            </div>
                            <div className="w-full md:w-full xl:w-1/3 mt-8 lg:mt-0">
                                <div className="m-4 py-8 lg:px-8 xl:px-16 ci-wrapper mx-auto">
                                    <Img fixed={data.page.contactPageAcf.contact.icon.imageFile.childImageSharp.fixed} alt={data.page.contactPageAcf.contact.icon.altText} style={{ zIndex: 9 }} />
                                    <div className="ci-divider" />
                                    <h6>{data.page.contactPageAcf.contact.title}</h6>
                                    <div dangerouslySetInnerHTML={{ __html: data.page.contactPageAcf.contact.telephone }} />
                                </div>
                            </div>
                            <div className="w-full md:w-full xl:w-1/3 mt-8 lg:mt-0">
                                <div className="m-4 py-8 lg:px-8 xl:px-16 ci-wrapper mx-auto">
                                    <Img fixed={data.page.contactPageAcf.contactEmail.icon.imageFile.childImageSharp.fixed} alt={data.page.contactPageAcf.contactEmail.icon.altText} style={{ zIndex: 9 }} />
                                    <div className="ci-divider" />
                                    <h6>{data.page.contactPageAcf.contactEmail.title}</h6>
                                    <div dangerouslySetInnerHTML={{ __html: data.page.contactPageAcf.contactEmail.email }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    {emailFailed && (
                        <p style={{ color: "#b70000", fontSize: "1.2rem" }}>{emailResponse}</p>
                    )}

                    {!emailSent
                        ?
                        <div className="container m-auto px-4 py-24 cu-question">
                            <h2 className="text-5xl lg:text-5xl">{data.page.contactPageAcf.questionTitle}</h2>
                            <div className="flex flex-wrap mt-8">
                                <div className="w-full lg:w-1/3 pt-4 lg:pt-0 px-8">
                                    <label for="fullName">{data.page.contactPageAcf.questionGroup.name}</label>
                                    <input type="text" name="fullName" id="fullName" />
                                </div>
                                <div className="w-full lg:w-1/3 pt-4 lg:pt-0 px-8">
                                    <label for="telephone">{data.page.contactPageAcf.questionGroup.mobileNumber}</label>
                                    <input type="text" name="telephone" id="telephone" />
                                </div>
                                <div className="w-full lg:w-1/3 pt-4 lg:pt-0 px-8">
                                    <label for="email">{data.page.contactPageAcf.questionGroup.email}</label>
                                    <input type="text" name="email" id="email" />
                                </div>
                                <div className="w-full px-8 mt-8" >
                                    <label for="txtArea">{data.page.contactPageAcf.questionGroup.message}</label>
                                    <textarea id="txtArea" />
                                </div>
                                <div className="w-full mt-8">
                                    <button onClick={contactUs} className="block m-auto px-4 py-2" style={{ minWidth: "100px" }}>
                                        {!loading ?
                                            data.page.contactPageAcf.questionGroup.button
                                            :
                                            <div className="bttn-loader">Loading...</div>
                                        }

                                    </button>
                                    {formError !== "" && (
                                        <span className="w-full text-center block pt-4" style={{ color: "red" }}>{formError}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        :
                        <div >
                            <div className="email-response" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                        </div>
                    }
                </section>

            </div>
        </Layout>
    )
}

export default ContactTemplate;

export const pageQuery = graphql`
    query GET_CONTACT_PAGE($id: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
                contactPageAcf{
                    introductionHeader
                    introductionBackgroundImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2000, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                                
                            }
                        }
                    }
                    contact{
                        title
                        icon{
                            sourceUrl
                            altText  
                            imageFile{
                                childImageSharp{
                                    fixed(width: 68,height:50, quality:100){
                                        base64
                                        width
                                        height
                                        src
                                        srcSet
                                    }
                                }
                            }
                        }
                        telephone
                    }
                    contactAddress{
                        title
                        icon{
                            sourceUrl
                            altText  
                            imageFile{
                                childImageSharp{
                                    fixed(width: 68,height:50, quality:100){
                                        base64
                                        width
                                        height
                                        src
                                        srcSet
                                    }
                                }
                            }
                        }
                        address
                    }
                    contactEmail{
                        title
                        icon{
                            sourceUrl
                            altText  
                            imageFile{
                                childImageSharp{
                                    fixed(width: 68,height:50, quality:100){
                                        base64
                                        width
                                        height
                                        src
                                        srcSet
                                    }
                                }
                            }
                        }
                        email
                    }
                    questionTitle
                    questionGroup{
                        name
                        mobileNumber
                        email
                        message
                        button
                    }
                }
            }
            template(id: "cG9zdDoyMDUw"){
                appCpt {
                    ...AppointmentFragment
                }
            }
            menu(id:"bmF2X21lbnU6MjI="){
                menuItems{
                    edges{
                        node{
                            url
                            label
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
            footer: template(id:"cG9zdDoyMjE5"){
                id
                title
                footerInformationTemplate{
                    address{
                        addressGoogleLink
                        addressLabel
                    }
                    hoursLabel
                    hoursTitle
                    informationTitle
                    supportEmail
                    telephoneNumber
                }
                footerAcf{
                    googlaMapsImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 750, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    city
                    callToActionText
                }
            }
        }
    }
`


